import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import './addQuiz.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';


import AWS from 'aws-sdk';
import axios from 'axios';

//storing image in bucket
AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
});


interface FormData {
    name: string; description: string; startDate: string; endDate: string; time: string; noQuestion: string;
}

const initialFormData: FormData = {
    name: '', description: '', startDate: '', endDate: '', time: '', noQuestion: ''
};


const AddQuiz = () => {

    const [isToggled, setToggled] = useState(false);


    const handleToggle = () => {
        setToggled(!isToggled);
    };


    const today = new Date().toISOString().split('T')[0];

    const navigate = useNavigate()
    const s3 = new AWS.S3();


    const [addButton, setAddButton] = useState(false);
    const [minEndDate, setMinEndDate] = useState(today);

    const [image, setImage] = useState(null);
    const [imageErr, setImageErr] = useState(false);
    const [img, setImg] = useState('');



    async function handleImageFile(e) {
        setImageErr(false);
        const selectedFile = e.target.files[0];



        if (selectedFile) {
            if (selectedFile.size > 5 * 1024 * 1024) { // Check if the file size is greater than 2MB
                setImageErr(true);
                toast.error('Image size must be 5MB or less', {
                    position: "bottom-right",
                });
                return;
            }

            setImage(selectedFile.name);

            const params = {
                Bucket: 'chaturvedi-v2',
                Key: `${Date.now()}.${selectedFile.name}`,
                Body: selectedFile,
                ACL: 'public-read-write'
            };

            try {
                const { Location } = await s3.upload(params).promise();
                setImg(Location);
            } catch (error) {
                toast.error(error, {
                    position: "bottom-right",
                });
            }
        }
    }


    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<FormData>>({});

    const [endData, setEndData] = useState('')

    const [checked, setChecked] = useState(true)


    const handleButtonToggle = async () => {
        setChecked(!checked)
    }




    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Check if the input is a number for 'time' and 'noQuestion'
        if ((name === 'time' || name === 'noQuestion') && isNaN(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: `Please enter a valid number for ${name}`,
            }));
        } else {
            setFormData({ ...formData, [name]: value });
            setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
        }
    };








    const validateForm = () => {
        const errors: Partial<FormData> = {};

        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!formData.description.trim()) {
            errors.description = 'Description is required';
        }

        if (!formData.startDate.trim()) {
            errors.startDate = 'Please enter your Star Date ';
        }

        if (!formData.endDate.trim()) {
            errors.endDate = 'Please enter your End Date';
        }

        if (!formData.time.trim()) {
            errors.time = 'Time must be number';
        }


        if (!formData.noQuestion.trim()) {
            errors.noQuestion = 'Number of Questions is required';
        }


        return errors;
    };


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const errors = validateForm();
            
            if (Object.keys(errors).length === 0) {
                
                const data = {
                    ...formData,
                    image: img,
                    numberOfQuestions: formData.noQuestion,
                    quizActivate: checked
                }

                
                try {
                    setAddButton(true)
                    axios.post(`${process.env.REACT_APP_API_BASE_URL}/quiz/add`, data)
                        .then((res) => {
                            
                            toast.success('Quiz Created Successfully!', { position: "bottom-right" });
                            navigate('/quiz/list')
                        }).catch((err) => {
                            setAddButton(false)
                            toast.error(err?.response?.data?.message, { position: "bottom-right" });
                        })
                }
                catch (err) {
                    setAddButton(false)
                    toast.error(err?.response?.data?.error, { position: "bottom-right" });
                }
            } else {
                setErrors(errors);
                return;
            }
        } catch (err) {
            toast.error(err, { position: "bottom-right" });
            
        }
    };



    const getMinimumDate = (date) => {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);

        return minDate.toISOString().split('T')[0];
    };



    return (
        <>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                    gap: '10', background: '#fff', padding: 15,
                    margin: 30, borderRadius: 10
                }}
            >
                <div style={{ margin: 4, width: '100%' }}>
                    <p
                        style={{
                            margin: 4, width: '100%', color: '#000',
                            fontWeight: 600, marginBottom: '30px'
                        }}>
                        Add Quiz
                    </p>
                </div>
                <Grid item xs={12}>

                    <div className='TopBox-Add-iser-2'>
                        <div style={{ margin: 9, width: '20%' }}>
                            <TextField className='add-user-inside'
                                label="Name" name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            {errors.name && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.name}
                                </span>
                            )}
                        </div>



                        <div>
                            <div style={{ margin: 9, width: '100%' }}>
                                <span>Select Start Date </span>

                                <TextField className='add-user-inside'
                                    type='date'
                                    name="startDate"
                                    inputProps={{
                                        min: today,
                                    }}
                                    value={formData.startDate}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        setMinEndDate(e.target.value);
                                    }}
                                />
                                {errors.startDate && (
                                    <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                        {errors.startDate}
                                    </span>
                                )}
                            </div>
                        </div>


                        <div style={{ margin: 9, width: '20%' }}>
                            <span>Select End Date </span>
                            <TextField className='add-user-inside'
                                type='date'
                                name="endDate"
                                inputProps={{
                                    min: formData.startDate == today ? getMinimumDate(minEndDate) : minEndDate,
                                }}
                                value={formData.endDate}
                                onChange={(e) => {
                                    handleInputChange(e)
                                    setEndData(e.target.value);

                                }}
                            />
                            {errors.endDate && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.endDate}
                                </span>
                            )}
                        </div>


                        <div style={{ margin: 9 }}>
                            <label className="switch">
                                <input type="checkbox" checked={checked} onChange={handleButtonToggle} />
                                <span className="slider round"></span>
                            </label>

                        </div>


                        <div style={{ margin: 9, width: '20%' }}>
                            <TextField className='add-user-inside'
                                label="Enter Quiz Time" name="time"
                                value={formData.time}
                                onChange={handleInputChange}
                            />
                            {errors.time && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.time}
                                </span>
                            )}
                        </div>



                        <div style={{ margin: 9, width: '20%' }}>
                            <TextField className='add-user-inside'
                                label="Number of Questions" name="noQuestion"
                                value={formData.noQuestion}
                                onChange={handleInputChange}
                            />
                            {errors.noQuestion && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.noQuestion}
                                </span>
                            )}
                        </div>


                    </div>


                    <div className='description-image-container'>
                        <div className='description-box-area' style={{ margin: 9, width: '100%' }}>
                            <ReactQuill
                                className='new-user-class'
                                value={formData.description}
                                onChange={(value) => {
                                    setFormData({ ...formData, description: value })
                                    setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));
                                }}
                            />
                            {errors.description && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.description}
                                </span>
                            )}
                        </div>


                    </div>

                    <div className='' style={{ margin: 12, width: '20%', position: 'relative' }}>
                        <input className='image-input-class-22' type="file" onChange={handleImageFile} />
                        <div className='attach-icon'>
                            <AttachFileIcon />
                            <label>choose an Image</label>
                        </div>
                        {img && <img className='image-inside-2' src={img} style={{ maxWidth: "170px" }} />}

                        {/* <input type="file" onChange={handleImageFile} /> */}
                        {/* <span className='error-msg'>Image size must be 5MB or less</span> */}
                    </div>
                </Grid>

                <div style={{ margin: 9, width: '100%' }}>
                    <Button variant="contained" type="submit" disabled={addButton == true}>
                        Submit
                    </Button>
                </div>
            </form>


        </>
    );
}
export default AddQuiz;



