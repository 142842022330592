import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid } from '@mui/material';
import Editor from 'react-simple-wysiwyg';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { socket } from 'src/App';
import './AddNotification.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
// const initialValues = { Type: "", Receiver: "", Title: "", Content: "" };
const initialValues = { Receiver: "", Title: "", Content: "" };


import AWS from 'aws-sdk';

//storing image in bucket
AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
    // signatureVersion: 'v4'
});


const validationSchema = Yup.object({
    Receiver: Yup.string()
        .required('Please select one Reciever'),
    // Type: Yup.string().required('Type is required'),
    Title: Yup.string().required('Title is required'),
    // Content: Yup.string().required('Content is required'),
});

const AddNotification = () => {
    const [html, setHtml] = useState('my <b>HTML</b>');

    function hnadleChange1(e: any) {
        setHtml(e.target.value);
    }

    const [addButton, setAddButton] = useState(false);
    const [contentRows, setContentRows] = useState(4); // State to manage the number of rows

    const navigate = useNavigate()
    const styles = {
        formerror: { color: 'red' },
        fromArea: { display: 'inlineFlex', flexDirection: 'column' }
    };

    const [image, setImage] = useState(null);
    const [imageErr, setImageErr] = useState(false);
    const [img, setImg] = useState('');

    const s3 = new AWS.S3();
    async function handleImageFile(e) {
        setImageErr(false);
        setImage(e.target.files[0]?.name);

        if (e.target.files[0]?.name) {
            const params = {
                Bucket: 'chaturvedi-v2',
                Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
                Body: e?.target?.files[0],
                ACL: 'public-read-write'
            };


            try {
                const { Location } = await s3.upload(params).promise();

                setImg(Location);
            } catch (error) {
                toast.error(error, {
                    position: "bottom-right",
                });
            }
        }
    }


    const [notificationData, setNotificationData] = useState([])

    const handleNotificationData = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/members/getAll`)
            console.log("data is = ", data?.data)
            setNotificationData(data?.data)


        } catch (error) {

        }
    }

    useEffect(() => {
        handleNotificationData()
    }, [])

    const [err, setErr] = useState('')
    function handleTextareaInput(event) {
        const textarea = event.target;
        textarea.style.height = 'auto'; // Reset the height to auto
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues, validationSchema: validationSchema,

        onSubmit: async (values) => {
            const requestBody = {
                ...values,
                image: img, // Assuming img contains the image URL
            };

            
            const arr = []
            try {
                setAddButton(true)
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notifications/addnotification`, requestBody)
                    .then(async (res) => {

                       
                        notificationData.map((item) => {
                            item?.fcmToken != undefined ? arr.push(item?.fcmToken) : ''

                        })


                        const newArr = []
                        socket.emit('usernoti', 'user')

                        arr.map((item) => {
                            if (newArr?.includes(item)) {
                                return
                            } else {
                                newArr.push(item)
                            }
                        })

                        console.log("newArr - ", newArr)

                        const data = {
                            fcmToken: newArr,
                            content: requestBody?.Content,
                            title: requestBody?.Title
                        }

                        

                        toast.success('Announcements created successfully!', { position: "bottom-right" });
                        if (arr.length != 0) {
                            try {
                                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/members/send-notification`, data)
                                navigate('/notifications/list');
                               
                            } catch (error) {
                                navigate('/notifications/list');

                            }
                        }

                    }).catch((err) => {
                        setAddButton(false)
                        toast.error(err?.response?.data?.message, { position: "bottom-right" });
                    })
            } catch (err) {
                setAddButton(false)
                toast.error(err?.response?.data?.message, { position: "bottom-right" });
            }

        }
    });




    return (
        <>
            {/* <ToastContainer /> */}

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '10',
                    background: '#fff',
                    padding: 15,
                    margin: 30,
                    borderRadius: 10
                }}
            >


                <Grid className='carrd-box' item xs={12}>
                    <Card>
                        <CardHeader title="Add Announcement " />
                        <Divider />
                        <CardContent>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '35ch' }
                                }}
                                noValidate
                                autoComplete="off"
                            >


                                <div className='Main-div-1' style={{ alignItems: 'flex-start' }}>
                                    <div style={{ margin: 9, position: 'relative' }}>
                                        <InputLabel className='heading-receiver' htmlFor="state">Reciever</InputLabel>
                                        <Select className='text-area-style22'
                                            sx={{ width: "35ch", ml: 1 }}
                                            label="Type"
                                            value={values?.Receiver}
                                            name="Receiver"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            id="Reciever"
                                            placeholder="Receiver"
                                        >
                                            <MenuItem value="All">All</MenuItem>
                                            <MenuItem value="Admin" >Admin</MenuItem>
                                            <MenuItem value="Member" selected>Member</MenuItem>
                                            <MenuItem value="Sub Admin">Sub Admin</MenuItem>
                                            {/* Add more state options here */}
                                        </Select>

                                        {errors.Receiver && touched.Receiver ? (
                                            <p style={styles.formerror} className="formerror my-class-new">
                                                {errors.Receiver}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div style={{ margin: 9 }}>
                                        <TextField className='text-area-style22'
                                            label="Title"
                                            name="Title"
                                            type='text'
                                            value={values?.Title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        {errors.Title && touched.Title ? (
                                            <p style={styles.formerror} className="formerror my-class-new">
                                                {errors.Title}
                                            </p>

                                        ) : null}
                                    </div>



                                    {/* <div style={{ margin: 9 }}>
                                        <TextField className='text-area-style22'
                                            label="Type"
                                            name="Type"
                                            type='text'
                                            value={values?.Type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />


                                        {errors.Type && touched.Type ? (
                                            <p style={styles.formerror} className="formerror my-class-new">
                                                {errors.Type}
                                            </p>

                                        ) : null}
                                    </div> */}
                                </div>
                                <div className='Main-div-1'>


                                    <div style={{ margin: 9, marginLeft: 18 }}>
                                        <textarea
                                            className='text-area-1 text-area-style22 auto-resize-textarea-2'
                                            placeholder='Content'
                                            id="Content"
                                            name="Content"
                                            value={values?.Content}
                                            onChange={(e) => {
                                                handleTextareaInput(e);
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            rows={4}
                                            cols={50}
                                        />
                                        <div>
                                            {/* Additional content */}
                                        </div>
                                    </div>

                                </div>


                                <div className='Main-div-1'>

                                    <div className='' style={{ margin: 12, position: 'relative', marginLeft: 18 }}>
                                        {/* <span> Image </span> */}
                                        <input className='image-input-class-22' type="file" onChange={handleImageFile} />
                                        <div className='attach-icon'>
                                            <AttachFileIcon />
                                            <label>choose an Image</label>
                                        </div>
                                        {img && <img className='image-fix' src={img} />}

                                        {imageErr && (
                                            <div style={{ color: 'red', paddingTop: '10px' }}>
                                                Image is required
                                            </div>
                                        )}
                                    </div>


                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>





                <div style={{ margin: 9, width: '100%' }}>
                    <Button className='ok-btn' variant='contained' onClick={() => handleSubmit} type="submit" disabled={addButton === true}>Submit</Button>
                </div>
            </form >
        </>
    );
}
export default AddNotification;



