import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import './addQuiz.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Autocomplete from '@mui/material/Autocomplete';


import AWS from 'aws-sdk';
import axios from 'axios';

//storing image in bucket
AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
});



interface FormData {
    title: String,
    category: string | string[];
}

const initialFormData: FormData = {
    title: '', category: []
};


const QuizQuestions = () => {

    const [categoryData, setCategoryData] = useState([]);
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [optionsCount, setOptionsCount] = useState(1);
    const [options, setOptions] = useState(['']);
    const [categorySelected, setCategorySelected] = useState(false);
    const [optionsFilled, setOptionsFilled] = useState(false);
    const [addButton, setAddButton] = useState(false)
    const [opetionDataErr, setOptionDataErr] = useState(false);

    const navigate = useNavigate();

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);

    };

    console.log(options, "optionsFilled = ", optionsFilled)

    const handleAddOption = () => {
        setOptionsCount(optionsCount + 1);
        setOptions([...options, '']); // Add an empty option
    };

    const [checkedOptions, setCheckedOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');


    const handleCheckboxChange = (option) => {
        setSelectedOption(option);
    };



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    };

    const category = async () => {
        try {
            let category = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/quiz/list`);
            setCategoryData(category?.data?.data);
        } catch (error) {
            console.log("error is : - ", error)
        }
    }

    useEffect(() => {
        category();
    }, [])


    const validateForm = () => {
        const errors: Partial<FormData> = {};
        if (!formData.title.trim()) {
            errors.title = 'Title is required';
        }


        return errors;
    };







    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const errors = validateForm();

            let id = []

            const dataString = JSON.stringify(formData?.category);
            const dataArray = JSON.parse(dataString);

            dataArray?.map((item) => {
                id.push(item?._id)
            })

            if (formData.category.length === 0) {
                return setCategorySelected(true)
            }


            if (Object.keys(errors).length === 0) {
                const data = {
                    ...formData,
                    quizData: id,
                    options: options.map((option, index) => ({
                        text: option,
                        checked: selectedOption === option, // Add this property
                    })),
                }
                

                try {
                    setAddButton(true)
                    axios.post(`${process.env.REACT_APP_API_BASE_URL}/quiz/add/question`, data)
                        .then((res) => {
                            toast.success('Quiz Created Successfully!', { position: "bottom-right" });
                            navigate('/quiz/question/list')
                        }).catch((err) => {
                            setAddButton(false)
                            toast.error(err?.response?.data?.message, { position: "bottom-right" });
                        })
                }
                catch (err) {
                    setAddButton(false)
                    toast.error(err?.response?.data?.error, { position: "bottom-right" });
                }
            } else {
                setErrors(errors);
                return;
            }
        } catch (err) {
            toast.error(err, { position: "bottom-right" });

        }
    };

    const [data, setData] = useState(false)


    const updateCategory = categoryData.map((item) => (
        < MenuItem key={item?._id} value={item?._id} > {item?.name}</MenuItem >
    ));


    useEffect(() => {
        if (formData?.category.length != 0) {
            setData(true);

        }
    }, [formData?.category.length != 0])


    return (
        <>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                    gap: '10', background: '#fff', padding: 15,
                    margin: 30, borderRadius: 10
                }}
            >
                <div style={{ margin: 4, width: '100%' }}>
                    <p
                        style={{
                            margin: 4, width: '100%', color: '#000',
                            fontWeight: 600, marginBottom: '30px'
                        }}>
                        Add Quiz Question
                    </p>
                </div>
                <Grid item xs={12} style={{ margin: 4, width: '100%' }}  >

                    <div className='TopBox-Add-iser-2'>
                        <div style={{ margin: 9, width: '40%' }}>
                            <TextField className='add-user-inside'
                                label="Title" name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                            />
                            {errors.title && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    {errors.title}
                                </span>
                            )}
                        </div>


                        <div style={{ margin: 9, width: '100%', maxWidth: '40%' }}>
                            <Autocomplete
                                id="category"
                                options={categoryData}
                                getOptionLabel={(option) => option.name}
                                multiple
                                style={{ width: '100%', }}
                                // value={formData.category}
                                onChange={(_, newValue) => {
                                    setCategorySelected(false);

                                    setFormData({
                                        ...formData,
                                        category: newValue,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select category"
                                        placeholder="Search..."
                                    />
                                )}
                            />
                            {categorySelected && (
                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                    category is required
                                </span>
                            )}
                            {/* 
    {errors.category && (
        <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
            {errors.category}
        </span>
    )} */}
                        </div>

                        {data ? (
                            <div>
                                <p>Please select your answer :</p>
                                <button style={{
                                    padding: '15px', background: 'primary', border: 'none', marginBottom: '15px'
                                }} className='btn' type="button" onClick={handleAddOption}>
                                    Click To Add More Options
                                </button>
                                {options.map((option, index) => (
                                    <div key={index}>
                                        <input
                                            style={{
                                                padding: '15px', background: 'primary', marginBottom: '15px'
                                            }}
                                            type="text"
                                            placeholder={`Option ${index + 1}`}
                                            value={option}
                                            onChange={(e) => {
                                                handleOptionChange(index, e.target.value);
                                                setOptionsFilled(false);
                                            }}
                                        />
                                        <input
                                            type="radio"
                                            id={`option_${index}`}
                                            name="fav_language"
                                            checked={selectedOption === option}
                                            onChange={() => handleCheckboxChange(option)}
                                        />
                                        <label htmlFor={`option_${index}`}>{option}</label>


                                    </div>

                                ))}
                            </div>
                        ) : null}




                    </div>




                </Grid>

                <div style={{ margin: 9, width: '100%' }}>
                    {/* <Button variant="contained" type="submit" disabled={addButton == true}> */}
                    <Button variant="contained" type="submit" disabled={addButton == true}>  Submit </Button>
                </div>
            </form >


        </>
    );
}
export default QuizQuestions;



