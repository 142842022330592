import { useEffect, useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';

import Select from 'react-select';
import csc from "country-state-city"
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './EditQuiz.css';
import AWS from 'aws-sdk';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
    // signatureVersion: 'v4'
});



const EditQuiz = () => {

    const navigate = useNavigate();
    const { id } = useParams();




    const [editorContent, setEditorContent] = useState('');
    const removeHTMLTags = (str) => {
        const div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText || "";
    };

    const today = new Date().toISOString().split('T')[0];
    const [minEndDate, setMinEndDate] = useState(today);
    //errors
    const [nameErr, setNameErr] = useState(false);
    const [startDateErr, setStartDateErr] = useState(false);
    const [endDateErr, setEndDateErr] = useState(false)
    const [descriptionErr, setDescriptionErr] = useState(false)

    const [quizTimeErr, setQuizTimeErr] = useState(false);
    const [numberOfQuestionsErr, setNumberOfQuestionsErr] = useState(false);
    const [quizActivateErr, setQuizActivateErr] = useState(false)


    //states
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('')
    const [quizTime, setQuizTime] = useState(null);
    const [numberOfQuestions, setNumberOfQuestions] = useState(null);
    const [quizActivate, setQuizActivate] = useState('')


    const [submitData, setSubmitData] = useState(false);




    const GetUserByID = async () => {
        try {
            const userData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/quiz/${id}`);

            const data = userData?.data?.data;
            const formattedStartDate = new Date(data?.startDate).toISOString().split('T')[0];
            const formattedEndDate = new Date(data?.endDate).toISOString().split('T')[0];

            setName(data?.name);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            setDescription(data?.description);
            setQuizTime(data?.time);
            setNumberOfQuestions(data?.numberOfQuestions);
            setChecked(data?.quizActivate)
            setImage(data?.image)

        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        GetUserByID()
    }, [])


    const [imageErr, setImageErr] = useState(false);
    const [img, setImg] = useState('');
    const s3 = new AWS.S3();

    async function handleImageFile(e) {
        setImageErr(false);
        const selectedFile = e.target.files[0];



        if (selectedFile) {
            if (selectedFile.size > 5 * 1024 * 1024) { // Check if the file size is greater than 2MB
                setImageErr(true);
                toast.error('Image size must be 5MB or less', {
                    position: "bottom-right",
                });
                return;
            }

            setImage(selectedFile.name);

            const params = {
                Bucket: 'chaturvedi-v2',
                Key: `${Date.now()}.${selectedFile.name}`,
                Body: selectedFile,
                ACL: 'public-read-write'
            };

            try {
                const { Location } = await s3.upload(params).promise();
                setImg(Location);
                setImage(Location)
            } catch (error) {
                toast.error(error, {
                    position: "bottom-right",
                });
            }
        }
    }

    const handleEdit = async (e) => {
        try {
            e.preventDefault();

            if (name == '') {
                setNameErr(true);
                return;
            }



            const cleanedEditorContent = removeHTMLTags(editorContent);

            if (description === '') {
                setDescriptionErr(true);
                return;
            }

            if (startDate == '') {
                setStartDateErr(true);
                return;
            }


            if (endDate == '') {
                setEndDateErr(true);
                return;
            }



            if (numberOfQuestions === '' || isNaN(numberOfQuestions)) {
                setNumberOfQuestionsErr(true);
                return;
            }

            if (quizTime === '' || isNaN(quizTime)) {
                setQuizTimeErr(true);
                return;
            }



            // if (image == '') {
            //     setImageErr(true);
            //     return;
            // }




            const data = {
                name,
                startDate, quizTime, numberOfQuestions, checked,
                endDate, description, image
            };


            setSubmitData(true)
            const finaldata = {
                ...data,
                quizActivate: checked,
                time: quizTime,
                image: img ? img : image,
                endDate: endDate,

            };

            console.log(finaldata, "==========",)

            try {
                let res = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/quiz/${id}`, finaldata);
                toast.success('Quiz Update Successfully!', { position: "bottom-right" });
                navigate('/quiz/list');

            } catch (error) {
                setSubmitData(false);
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }

        } catch (error) {
            setSubmitData(false);
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }

    const getMinimumDate = (date) => {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);

        return minDate.toISOString().split('T')[0];
    };


    const [checked, setChecked] = useState(true)


    const handleButtonToggle = async () => {
        setChecked(!checked)
    }



    return (
        <>
            <Helmet>
                <title>Edit Quiz</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Quiz" />
                            <Divider />
                            <CardContent>

                                <form action="" onSubmit={handleEdit}>

                                    <div className='box-1-top-22'>
                                        <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Name"
                                                name="name"
                                                type='text'
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                    setNameErr(false);
                                                }}
                                            />
                                            {nameErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Name is required.
                                                </span>
                                            )}
                                        </div>


                                        <div style={{ margin: 9 }}>
                                            <label className="switch">
                                                <input type="checkbox" checked={checked}
                                                    onChange={handleButtonToggle}
                                                />
                                                <span className="slider round"></span>
                                            </label>

                                        </div>






                                        <div>
                                            <TextField className='date-box'
                                                id="outlined-required"
                                                type='date'
                                                name="startDate"
                                                value={startDate}
                                                inputProps={{
                                                    min: today,
                                                }}
                                                onChange={
                                                    (e) => {
                                                        setStartDate(e.target.value)
                                                        setStartDateErr(false)
                                                        setMinEndDate(e.target.value);

                                                    }}
                                            />
                                            {startDateErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Start Date is required
                                                </span>
                                            )}
                                        </div>


                                        <div>
                                            <TextField className='date-box'
                                                id="outlined-required"
                                                type='date'
                                                name="endDate"
                                                value={endDate}
                                                inputProps={{
                                                    min: startDate == today ? getMinimumDate(minEndDate) : minEndDate
                                                }}

                                                onChange={
                                                    (e) => {
                                                        setEndDate(e.target.value)
                                                        setEndDateErr(false)
                                                    }}
                                            />
                                            {endDateErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    End Date is required
                                                </span>
                                            )}
                                        </div>

                                        <div style={{ margin: 9, width: '20%' }}>
                                            <TextField className='add-user-inside'
                                                label="Enter Quiz Time" name="time"
                                                value={quizTime}
                                                onChange={(e) => {
                                                    setQuizTime(e.target.value)
                                                    setQuizTimeErr(false)
                                                }}
                                            />
                                            {quizTimeErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Quiz Time is Required and must be number
                                                </span>
                                            )}
                                        </div>

                                        <div style={{ margin: 9, width: '20%' }}>
                                            <TextField className='add-user-inside'
                                                label="Number of Questions" name="noQuestion"
                                                value={numberOfQuestions}
                                                onChange={(e) => {
                                                    setNumberOfQuestions(e.target.value)
                                                    setNumberOfQuestionsErr(false)
                                                }}
                                            />
                                            {numberOfQuestionsErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Number of Questions are required and must be number
                                                </span>
                                            )}
                                        </div>



                                    </div>


                                    <div className='description-image-box'>
                                        <div style={{ margin: 12, width: '40%' }}>
                                            <label>Description</label>
                                            <ReactQuill
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e)
                                                    setDescriptionErr(false)

                                                }}
                                                modules={{
                                                    toolbar: [
                                                        [{ header: '1' }, { header: '2' }],
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                                        ['link'],
                                                    ],
                                                }}
                                                placeholder="Write a description..."
                                            />
                                            {descriptionErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Description is required.
                                                </span>
                                            )}
                                        </div>


                                    </div>
                                    <div className='' style={{ margin: 12, position: 'relative' }} >
                                        <input className='image-input-class-22' type="file" onChange={handleImageFile} />
                                        <div className='attach-icon'>
                                            <AttachFileIcon />
                                            <label>choose an Image</label>
                                        </div>
                                        {image && <img className='image-inside' src={image} />}

                                    </div>

                                    <div>
                                        <Button className="button-box" type='submit' disabled={submitData == true}>Submit</Button>
                                        <Button className="button-box" onClick={() => navigate('/quiz/list')}>Back to Quiz</Button>
                                    </div>
                                </form>

                                {/* <Box className='box-1-top'
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditQuiz;
