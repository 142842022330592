import { useEffect, useState } from 'react';
import { Box, TextField, Card, CardContent, Typography, Button, CardHeader, Container, Divider, Grid, Autocomplete } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';

import Select from 'react-select';
import csc from "country-state-city"
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './EditQuiz.css';
import AWS from 'aws-sdk';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

AWS.config.update({
    accessKeyId: 'AKIAWX5OXDFHYFDDAEEF',
    secretAccessKey: '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',
    region: 'ap-south-1'
    // signatureVersion: 'v4'
});



const EditQuizQuestion = () => {

    const navigate = useNavigate();
    const { id } = useParams()

    const today = new Date().toISOString().split('T')[0];
    const [minEndDate, setMinEndDate] = useState(today);
    const [titleErr, setTitleErr] = useState(false);

    const [title, setTitle] = useState('');
    const [options, setOptions] = useState([]);



    const [submitData, setSubmitData] = useState(false);
    let [quizData, setQuizData] = useState([])
    const [categoryData, setCategoryData] = useState([]);


    const category = async () => {
        try {
            let category = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/quiz/list`);
            setCategoryData(category?.data?.data);
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }





    const GetUserByID = async () => {
        try {
            const userData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/quiz/list/question/${id}`);
            const data = userData?.data?.data;
            setOptions(data?.options);

            try {
                const quizDataPromises = userData?.data?.data?.quizData.map(async (item) => {
                    const newData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/quiz/${item}`);
                    return newData?.data?.data;
                });
                const allQuizData = await Promise.all(quizDataPromises);
                setQuizData(allQuizData);
            } catch (error) {
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }

            setTitle(data?.title);

        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }


    useEffect(() => {
        category();
        GetUserByID()
    }, [])



    const handleEdit = async (e) => {
        try {
            e.preventDefault();

            if (title == '') {
                setTitleErr(true);
                return;
            }



            let idData = [];
            quizData?.map((item) => {
                idData.push(item?._id)
            });

            const finaldata = {
                options: options,
                title: title,
                quizData: idData

            };

            try {
                setSubmitData(true);
                let res = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/quiz/question/${id}`, finaldata);
                toast.success('Quiz Update Successfully!', { position: "bottom-right" });
                navigate('/quiz/question/list');

            } catch (error) {
                setSubmitData(false);
                toast.error(error?.response?.data?.message, { position: "bottom-right" });
            }



        } catch (error) {
            setSubmitData(false);
            toast.error(error?.response?.data?.message, { position: "bottom-right" });
        }
    }

    console.log("Options is -------------- ", options)
    const handleOptionChange = (index, value) => {
        setOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[index].text = value;
            return updatedOptions;
        });
    };

    const handleCheckboxChange = (index) => {
        setOptions((prevOptions) => {
            const updatedOptions = prevOptions.map((option, i) => ({
                ...option,
                checked: i === index,
            }));
            return updatedOptions;
        });
    };



    return (
        <>
            <Helmet>
                <title>Edit Quiz</title>
            </Helmet>

            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Edit Quiz" />
                            <Divider />
                            <CardContent>

                                <form action="" onSubmit={handleEdit}>

                                    <div className='box-1-top-22'>
                                        <div>
                                            <TextField
                                                id="outlined-required"
                                                label="Title"
                                                name="title"
                                                type='text'
                                                value={title}
                                                onChange={(e) => {
                                                    setTitle(e.target.value)
                                                    setTitleErr(false);
                                                }}
                                            />
                                            {titleErr && (
                                                <span style={{ color: 'red', display: 'flex', paddingTop: '10px' }}>
                                                    Title is required.
                                                </span>
                                            )}
                                        </div>





                                    </div>



                                    <div style={{ margin: 9, width: '100%', maxWidth: '40%' }}>
                                        <Autocomplete
                                            id="category"
                                            options={categoryData}
                                            getOptionLabel={(option) => option.name}
                                            multiple
                                            style={{ width: '100%' }}
                                            value={quizData.map((quiz) => ({ _id: quiz._id, name: quiz.name }))}
                                            onChange={(_, newValue) => {

                                                const uniqueValues = newValue.filter((value, index, self) => {
                                                    return index === self.findIndex((v) => v._id === value._id);
                                                });
                                                setQuizData(uniqueValues.map((item) => ({ _id: item._id, name: item.name })));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Category"
                                                    placeholder="Search..."
                                                />
                                            )}
                                        />




                                    </div>

                                    <div style={{ margin: 9, width: '100%', maxWidth: '40%' }}>
                                        {/* <p>Please select your answer :</p>
                                        <button
                                            style={{
                                                padding: '15px',
                                                background: 'primary',
                                                border: 'none',
                                                marginBottom: '15px',
                                            }}
                                            className='btn'
                                            type="button"
                                        // onClick={handleAddOption}
                                        >
                                            Click To Add More Options
                                        </button> */}
                                        {options.map((option, index) => (
                                            <div key={index}>
                                                <input
                                                    style={{
                                                        padding: '15px',
                                                        background: 'primary',
                                                        marginBottom: '15px',
                                                    }}
                                                    type="text"
                                                    placeholder={`Option ${index + 1}`}
                                                    value={option.text}
                                                    onChange={(e) => {
                                                        handleOptionChange(index, e.target.value);
                                                    }}
                                                />
                                                <input
                                                    type="radio"
                                                    id={`option_${index}`}
                                                    name={`fav_language_${index}`} 
                                                    checked={option.checked}
                                                    onChange={() => handleCheckboxChange(index)}
                                                />
                                                <label htmlFor={`option_${index}`}>{option.text}</label>
                                            </div>
                                        ))}
    
                                    </div>


                                    <div>
                                        <Button className="button-box" type='submit' disabled={submitData == true}>Submit</Button>
                                        <Button className="button-box" onClick={() => navigate('/quiz/list')}>Back to Question</Button>
                                    </div>
                                </form>

                                {/* <Box className='box-1-top'
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EditQuizQuestion;




